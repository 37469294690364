import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {CurrencyOverview} from '../domain/currency-overview';
import {ChartTypes} from '../domain/chart-types';
import {
    NumberFormatterCallbackFunction,
    PointSelectCallbackFunction,
    PointUnselectCallbackFunction,
    TooltipFormatterCallbackFunction,
    TooltipFormatterContextObject,
    TooltipOptions
} from 'highcharts';
import {TranslateService} from '@ngx-translate/core';
import {InsightsPeriod} from '../domain/insights-period';
import {DecimalAmountPipe} from '../pipes/decimal-input/decimal-input.pipe';

@Injectable({providedIn: 'root'})
export class ChartHelper {

    constructor(private readonly amountPipe: DecimalAmountPipe,
                private readonly platform: Platform,
                private translateService: TranslateService) {
    }

    public static getBubbleChartOptions(positiveSeries: CurrencyOverview[],
                                        negativeSeries: CurrencyOverview[],
                                        selectHandler: PointSelectCallbackFunction,
                                        unselectHandler: PointUnselectCallbackFunction,
                                        toolTip: TooltipOptions): Highcharts.Options {
        const positiveData = ChartHelper.getBubbleChartFormattedData(positiveSeries);
        const negativeData = ChartHelper.getBubbleChartFormattedData(negativeSeries);

        return {
            chart: {
                animation: false,
                type: ChartTypes.BUBBLE
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            tooltip: toolTip,
            plotOptions: {
                series: {
                    allowPointSelect: true,
                    states: {
                        inactive: {
                            opacity: 1
                        }
                    },
                    point: {
                        events: {
                            unselect: unselectHandler,
                            select: selectHandler
                        }
                    },
                    shadow: false,
                    cursor: 'pointer',
                },
                packedbubble: {
                    states: {
                        hover: {
                            enabled: false
                        },
                    },
                    draggable: false,
                    marker: {
                        lineColor: null,
                        lineWidth: 0,
                        fillOpacity: 1
                    },
                    opacity: 1,
                    minSize: 50,
                    maxSize: 160
                }
            },
            title: {text: ''},
            series: [
                {
                    name: '',
                    type: ChartTypes.BUBBLE,
                    data: positiveData,
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}',
                        style: {
                            color: 'white',
                            textOutline: '',
                            fontWeight: 'bold'
                        }
                    }
                },
                {
                    name: '',
                    type: ChartTypes.BUBBLE,
                    data: negativeData,
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}',
                        style: {
                            color: 'white',
                            textOutline: '',
                            fontWeight: 'bold'
                        }
                    }
                }
            ]
        };
    }

    public static getBubbleChartFormattedData(settlements: CurrencyOverview[]): Highcharts.PointOptionsType[] {
        return settlements.map(settlement => {
                return {
                    name: settlement.currency,
                    value: settlement.percentage
                };
            }
        );
    }

    public static getBubbleChartTooltip(tooltipClassName: string,
                                        formatter: TooltipFormatterCallbackFunction): TooltipOptions {
        return {
            backgroundColor: 'white',
            borderWidth: 0,
            borderRadius: 4,
            useHTML: true,
            className: tooltipClassName,
            shape: 'rect',
            formatter: formatter
        };
    }

    getSettlementOverviewTooltipFormatter(currencyOverviews: CurrencyOverview[], baseCurrency: string)
        : TooltipFormatterCallbackFunction {
        const amountPipe = this.amountPipe;
        return function (this: TooltipFormatterContextObject): string {
            const currentCurrency = currencyOverviews.find(settlement => settlement.currency === this.point.name);
            const netAmount = amountPipe
                .transform(currentCurrency.totalNetAmount, DecimalAmountPipe.AMOUNT_FORMAT_0_DIGITS);
            const tradingVolume = amountPipe
                .transform(currentCurrency.tradingVolume, DecimalAmountPipe.AMOUNT_FORMAT_0_DIGITS);

            const tradeCurrencyFormat = `
        <div>
        <div class="first-tooltip-row">
            <strong>${currentCurrency.currency}</strong> <span>${netAmount}</span>
        </div>`;
            const baseCurrencyFormat =
                currentCurrency.currency !== baseCurrency
                    ? `    <div class="second-tooltip-row">
                  <strong>${baseCurrency}</strong> <span>${tradingVolume}</span>
                 </div>
             </div>`
                    : `</div>`;

            return tradeCurrencyFormat.concat(baseCurrencyFormat);
        };
    }


    public getPositionBarChartOptions(tradingCcyBuyAmounts: number[],
                              tradingCcySellAmounts: number[],
                              contraCcyBuyAmounts: number[],
                              contraCcySellAmounts: number[],
                              tradingCcy: string,
                              contraCcy: string,
                              chartLabels: string[],
                              classNames: string[],
                              colors: string[],
                              numberFormatter: NumberFormatterCallbackFunction): Highcharts.Options {
        return {
            chart: {
                height: this.platform.height() - 520,
                numberFormatter: numberFormatter,
            },
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            legend: {
                enabled: false
            },
            xAxis: {
                categories: chartLabels
            },
            yAxis: {
                allowDecimals: false,
                title: {
                    text: ''
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    borderRadius: 5
                }
            },
            series: [{
                type: 'column',
                name: contraCcy,
                data: contraCcyBuyAmounts,
                stack: 'contraCurrency',
                className: classNames[0],
                minPointLength: 5,
                color: colors[2]
            }, {
                type: 'column',
                name: contraCcy,
                data: contraCcySellAmounts,
                stack: 'contraCurrency',
                className: classNames[1],
                minPointLength: 5,
                color: colors[3]
            }, {
                type: 'column',
                name: tradingCcy,
                data: tradingCcyBuyAmounts,
                stack: 'tradingCurrency',
                className: classNames[2],
                minPointLength: 5,
                color: colors[0]
            }, {
                type: 'column',
                name: tradingCcy,
                data: tradingCcySellAmounts,
                stack: 'tradingCurrency',
                className: classNames[3],
                minPointLength: 5,
                color: colors[1]
            }]
        };
    }

    public getSettlementBarChartOptions(ccyBuyAmounts: number[],
                                        ccySellAmounts: number[],
                                        tradingCcy: string,
                                        chartLabels: string[],
                                        color: string,
                                        numberFormatter: NumberFormatterCallbackFunction): Highcharts.Options {
        return {
            chart: {
                height: 250,
                numberFormatter: numberFormatter,
            },
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            legend: {
                enabled: false
            },
            xAxis: {
                categories: chartLabels
            },
            yAxis: {
                allowDecimals: false,
                title: {
                    text: ''
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    borderRadius: 5,
                }
            },
            series: [{
                type: 'column',
                name: tradingCcy,
                data: ccyBuyAmounts,
                minPointLength: 5,
                color: color
            }, {
                type: 'column',
                name: tradingCcy,
                data: ccySellAmounts,
                minPointLength: 5,
                color: color,
                opacity: 0.6
            }]
        };
    }

    getBarChartNumberFormatter(): NumberFormatterCallbackFunction {
        return (value: number): string => this.amountPipe.transform(value, DecimalAmountPipe.AMOUNT_FORMAT_0_DIGITS);
    }

    getTranslationsForChartLabels(label: string, activeTenor: InsightsPeriod): string {
        switch (activeTenor) {
            case InsightsPeriod.DAY:
                return this.translateService.instant('insights.days-labels.' + label);
            case InsightsPeriod.WEEK:
                return `W${label}`;
            case InsightsPeriod.MONTH:
                return this.translateService.instant('insights.months-labels.' + label);
            case InsightsPeriod.QUARTER:
                return this.translateService.instant('insights.quarters-labels.' + label);
            case InsightsPeriod.YEAR:
                return label;
            default:
                return this.translateService.instant('insights.months-labels.' + label);
        }
    }
}
